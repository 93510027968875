<template>
  <div>

    <!-- swiper1 -->
    <swiper
      ref="swiperTop"
      class="swiper-gallery gallery-top"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(image, index) in $store.state.appImage.images"
        :key="index"
      >
        <b-img
          :src="image"
          fluid
        />
      </swiper-slide>

      <div
        slot="button-next"
        class="swiper-button-next swiper-button-white"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev swiper-button-white"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import imageStoreModule from '@/views/images/imageStoreModule'
import router from '@/router'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        loopedSlides: this.imageUrls.length,
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  setup() {
    const IMAGES_STORE_MODULE = 'appImage'
    const { resourceType, resourceId } = router.currentRoute.params
    const imageUrls = ref([])
    // Register module
    if (!store.hasModule(IMAGES_STORE_MODULE)) store.registerModule(IMAGES_STORE_MODULE, imageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(IMAGES_STORE_MODULE)) {
        store.commit('appImage/CLEAR_IMAGES')
        return store.unregisterModule(IMAGES_STORE_MODULE)
      }
    })

    store.dispatch('appImage/fetchImageUrls', { resourceType, resourceId })

    return {
      imageUrls,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
    })
  },
}
</script>

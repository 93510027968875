import axios from '@axios'

export default {
  namespaced: true,
  state: {
    images: [],
  },
  getters: {},
  mutations: {
    ADD_IMAGE(state, image) {
      state.images.push(image)
    },
    CLEAR_IMAGES(state) {
      state.images = []
    },
  },
  actions: {
    fetchImageUrls(ctx, { resourceType, resourceId }) {
      axios
        .get(`/images/${resourceType}/${resourceId}`)
        .then(response => {
          response.data.forEach(item => {
            axios.get(`/images/${item.id}`, { responseType: 'blob' }).then(response => {
              const reader = new FileReader()
              reader.readAsDataURL(response.data)
              reader.onloadend = () => {
                ctx.commit('ADD_IMAGE', reader.result)
              }
            }).catch(error => { console.log(error) })
          })
        })
        .catch(error => { console.log(error) })
    },
    fetchImage(ctx, { imageId }) {
      axios
        .get(`/images/${imageId}`, { responseType: 'blob' })
    },

  },
}
